import { SEO } from "@app/ui/seo";
import { isBrowser } from "@app/utils";
import { PageProps } from "gatsby";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function Terms({}: PageProps) {
  return (
    <>
      <SEO title="DeckedLink - Terms of service" />
      {isBrowser() ? (
        <QueryClientProvider client={queryClient}>
          {" "}
          {/* <TrackableNotionViewer path="" id={"todo.todo"} />{" "} */}
          TODO
        </QueryClientProvider>
      ) : null}
    </>
  );
}

export default Terms;
